import React, {useRef, useState} from 'react';
import SearchGalleries from '../../../components/companyPost/searchGalleries';
import {storageService} from '../../../services/storage.service';
import {companyPostService} from '../../../services/companyPost.service';
import {SelectUsers} from "../../../components/Helpers/selectUsers";
import {AudienceUsers} from "../../../components/Helpers/audienceUsers";
import {FaSave, FaTimesCircle} from "react-icons/fa";
import {Button, Col, Form, Input, Row} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {HeadingAdmin} from "../../components/headingAdmin/headingAdmin";
import Swal from "sweetalert2";
import './galleries.scss'
import {TitleAdmin} from "../../components/titleAdmin/titleAdmin";
import {InputFile} from "../../components/inputFile/inputFile";

export const AdminGallery = () => {

    const [companyInit, setCompanyInit] = useState({
        CompanyPostId: 0,
        Title: '',
        Subtitle: '',
        Text: '',
        DepartmentId: 0,
        JobRoleId: 0
    });
    const [showEdit, setShowEdit] = useState(false);
    const [userSelec, setUserSelec] = useState([]);
    const [images, setImages] = useState([]);
    const [postType, setPostType] = useState(1);
    const gridRef = useRef();
    const [imagesUpdated, setImagesUpdate] = useState(false);

    const clearObjectCompany = () => setCompanyInit({
        CompanyPostId: 0,
        Title: '',
        Subtitle: '',
        Text: '',
        DepartmentId: 0,
        JobRoleId: 0
    })

    const onSave = () => {
        if (userSelec.length > 0) {
            companyInit.PostType = postType;
            companyInit.ListUser = userSelec;

            if (images.length === 0) Swal.fire({text: "Debes elegir al menos una imagen", icon: "error"});

            if (parseInt(companyInit.CompanyPostId) === 0) {
                companyPostService.create(companyInit, images).then((res) => gridRef.current.refreshDataGrid());
                handleReset();
            } else {
                const imagesToSave = [];
                if(imagesUpdated) {
                    images.forEach(img => {
                        if(!img.path) imagesToSave.push(img);
                    })
                }
                companyPostService.update(companyInit,  imagesUpdated ? imagesToSave : []).then((res) => gridRef.current.refreshDataGrid());
                handleReset();
            }

        } else Swal.fire({ text: "Debes elegir al menos un usuario", icon: "error" });
    }

    const handleSelectFiles = (newFiles) => {
        if(companyInit.CompanyPostId > 0) {
            setImagesUpdate(true);
            setImages(newFiles);
        } else setImages(newFiles)
    }

    const handleRemoveFiles = (files,file) => {
       setImages(files);
       if(companyInit.CompanyPostId > 0)
           handleDeleteImageGallery(file.entityId, file.entityTypeId, file.sequence)
    }

    const handleReset = () => {
        clearObjectCompany();
        setShowEdit(false);
        setUserSelec([]);
        setPostType(1);
        setImages([]);
    }

    const handleOnNewGallery = () => {
        setCompanyInit(companyInit);
        setShowEdit(true);
        setUserSelec([]);
        setImages([]);
        setPostType(1);
    };

    const handleRowSelect = (gridItem) => {
        setShowEdit(true);
        setPostType(gridItem.postType);
        setCompanyInit({
            CompanyPostId: gridItem.companyPostId,
            Title: gridItem.title,
            Subtitle: gridItem.subtitle,
            Text: gridItem.text,
            PostType: gridItem.postType
        });
        companyPostService.getCompanyPostImagesPathById(gridItem.companyPostId).then((result) => {
            if(result.files) {
                result.files.map(async (img) => img.path = await fetch(img.path).then(r => r.blob())
                        .then(blobFile => new File([blobFile], `img_${img.fileName}`, {type: "image/png",})));
                setImages(result.files);
            }
        });
    }

    const handleDeleteImageGallery = (entityId, entityTypeId, sequence) => {
        storageService.deleteStorage({
            EntityId: entityId,
            EntityTypeId: entityTypeId,
            Sequence: sequence
        }).then(res =>  setImages(images.filter((image) => image.sequence !== sequence)))
    };

    const handleInputChange = (e) => setCompanyInit({...companyInit, [e.target.name]: e.target.value})

    return (
        <div className="dashboard-admin-container">
            <TitleAdmin
                title={'Lista de álbumes'}
                subtitle={'Gestiona tus galerías'}
                btnBack={false}
            />
            <HeadingAdmin
                text={!(showEdit) ? 'Agregar álbum' : 'Cancelar'}
                icon={(!showEdit) ? <PlusCircleOutlined /> : <FaTimesCircle />}
                event={() => !(showEdit) ? handleOnNewGallery() : handleReset()}
            />
            {!(showEdit) ?
                <SearchGalleries
                    handleRowSelect={handleRowSelect}
                    ref={gridRef}
                />
                :
                <Form
                    onFinish={onSave}
                    className="form-backoffice"
                    layout={'vertical'}
                >
                    <Row gutter={16}>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Título de álbum"
                                htmlFor={'Title'}
                                name="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: '*Campo Requerido'
                                    }
                                ]}
                                initialValue={companyInit.Title}
                            >
                                <Input
                                    type="text"
                                    name="Title"
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Archivos seleccionado(s)"
                            >
                                <Col xs={24}>
                                    <InputFile
                                        onFilesSelected={handleSelectFiles}
                                        width={1280}
                                        height={1280}
                                        edit={(companyInit.CompanyPostId > 0)}
                                        valueFile={images}
                                        nameFile={'images'}
                                        accept={['image']}
                                        oneFile={false}
                                        maxFiles={50}
                                        deleteImage={handleRemoveFiles}
                                    />
                                </Col>
                            </Form.Item>
                        </Col>
                        {companyInit.CompanyPostId > 0 ?
                            <AudienceUsers
                                targetId={companyInit.CompanyPostId}
                                eventType="Galeria"
                                selectUser={setUserSelec}
                                showBtn={(showEdit)}
                            />
                            :
                            <SelectUsers selectUser={setUserSelec} title={"Entrada dirigida a:"}/>
                        }
                        <Col xs={24}>
                            <div className="form-backoffice-footer">
                                <Button type="primary" htmlType={'submit'}>
                                    <p>Guardar</p>
                                    <FaSave />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            }
        </div>
    )
}
