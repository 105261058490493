import React, { useState, useEffect } from "react";
import { companyPostService } from "../../services/companyPost.service";
import { storageService } from "../../services/storage.service";
import {FaTrash} from "react-icons/fa";
import {Button} from "antd";
import "./companyPostAttachment.scss";
import {IoIosAttach} from "react-icons/io";

export function CompanyPostAttachment(props) {
  const [localStorage, setlocalStorage] = useState([]);

  async function getAttachments() {
    if (props.CompanyPostId != null && props.CompanyPostId > 0) {
      companyPostService
        .getCompanyPostFilesById(props.CompanyPostId)
        .then((result) => {
          if (result != null && result.files != null) {
            setlocalStorage(result.files);
          }
        });
    }
  }

  useEffect(() => {
    getAttachments();
  }, [props.CompanyPostId]);

  const handleDelete = (event, data) => {
    storageService
      .deleteStorage({
        EntityId: data.entityId,
        EntityTypeId: data.entityTypeId,
        Sequence: data.sequence,
      })
      .then((result) => {
        setlocalStorage(
          localStorage.filter(function (storage) {
            return storage.sequence !== data.sequence;
          })
        );
      });
    event.stopPropagation();
  };



  return (
    <div className={'list-attachment'}>
      {localStorage != null && localStorage.length > 0 ? (
          <>
              <p>Archivos adjuntos</p>
              <ul className="list-attachment__list">
                  {localStorage.map((item) => (
                      <li className={'list-attachment__item'}>
                          <a
                              className="ant-btn ant-btn-link"
                              key={
                                  item.entityId + "-" + item.entityTypeId + "-" + item.sequence
                              }
                              download={item.fileName} href={`data:application/octet-stream;base64,${item.file}`}
                          >
                              <span className={'icon-attach'}><IoIosAttach/></span>
                              <p>{item.fileName}</p>
                          </a>
                          {props.IsDelete != null && props.IsDelete ? (
                              <Button
                                  htmlType={'button'}
                                  type={'link'}
                                  onClick={(event) => handleDelete(event, item)}
                                  icon={<FaTrash/>}
                              />
                          ) : null}
                      </li>
                  ))}
              </ul>
          </>
      ) : null}
    </div>
  );
}
