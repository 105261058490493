import React, { useEffect, useState } from "react";
import { Button, List, Result, Tag } from "antd";
import { FaChevronRight, FaEdit } from "react-icons/fa";
import { Card } from "reactstrap";
import {useHistory, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { ticketsEventService } from "../../services/ticketsEvent.service";

export const BoxTickets = () => {
  const history = useHistory();
  const params = useParams();
  const [events, setEvents] = useState([]);
  const [countEventsToApprove, setCountEventsToApprove] = useState(0);
  const roleName = useSelector((state) => state.userSettings.roleName);
  {/*<Button
      type={"primary"}
      onClick={() => history.push("/course-view")}
      className={(!(params.courseId)) && ('active')}
    >
      Mis eventos
      <FaChevronRight />
    </Button>*/}
  const data = [
    <Button
        type={"primary"}
        onClick={() => history.push("/course-view/approve-event")}
        className={((params.courseId === 'approve-event')) && ('active')}
    >
      Por autorizar <Tag color={"#ff2f24"}>{countEventsToApprove}</Tag>
      <FaChevronRight />
    </Button>
  ];

  useEffect(() => {
    const init = async () => {
      const result = await ticketsEventService.getAvailableToSale();
      if (result.events) {
        setEvents(result.events);
        setCountEventsToApprove(result.countEventsToApprove);
      }
    };

    init();
  }, []);

  const handleOpenView = (url) => {
    window.open(url, "_blank");
  };

  return (
    <section className="box-tickets">
      <aside className={"box-tickets-aside"}>
        <Button
          type={"primary"}
          className={"btn-pride-ticket"}
          htmlType={'button'}
          onClick={() => history.push("/course-view/new-event")}
        >
          <FaEdit />
          Publicar un evento
        </Button>
        {
          (roleName === 'Recursos Humanos') && (
            <List
                  size="large"
                  header={false}
                footer={false}
                bordered
                dataSource={data}
                renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          )
        }
      </aside>
      <div className={"box-tickets-body"}>
        {events.length === 0 ? (
          <Result
            status="info"
            title="No hay eventos publicados en este momento"
          />
        ) : (
          <div className="content_body_items">
            {events.map((item, index) => (
              <Card
                className={"card-pride-ticket"}
                lang={"en"}
                onClick={() => handleOpenView(item.eventUrl)}
                key={index}
              >
                <div className="card_ticket_poster">
                  <img
                    className="poster__blur"
                    src={item.eventImage}
                    alt={item.eventName}
                    title={item.eventName}
                  />
                  <img
                    src={item.eventImage}
                    alt={item.eventName}
                    title={item.eventName}
                  />
                </div>

                <div className={"card-pride-ticket-content"}>
                  <Tag>{item.eventSector}</Tag>
                  <p className={"title"}>
                    {item.eventName.length >= 36
                      ? item.eventName.substring(0, 36) + "..."
                      : item.eventName}
                  </p>
                  <p className={"date"}>
                  {item.celebrationDate ? moment(item.celebrationDate).format(
                      "DD/MM/YYYY,  HH:mm A"
                    ) : null }
                  </p>
                  <p className={"location"}>{item.locationName}</p>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
