import React, { useEffect, useState } from "react";
import { companyPostViewService } from "../../services/compayPostView.service";
import { backendService } from "./../../services/backend.service";
import { CompanyPostAttachment } from "../../components/companyPost/companyPostAttachment";
import "./ourCompany.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { CommentsBox } from "./commentsBox";
import bgGallery from "../../assets/imgs/icon-images.png";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
import { FooterHome } from "../../components/footer/footerHome";
import { CompanyPostCategoryTypes } from "../../enums/companyPostCategoryTypes";

export function CompanyPostPrimary(props) {
  const [comments, setComments] = useState(0);
  const [postComment, setPostComment] = useState({});
  const [commentBandera, setCommentBandera] = useState(0);
  const [postTotalLikes, setPostTotalLikes] = useState(0);
  const postCategoryId = 1;
  const postType = 1;
  const [showCommentBox, setShowCommentBox] = useState("");

  const handleCommentBox = () =>
    setShowCommentBox(showCommentBox === "" ? "is-show" : "");

  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const AsidePurchaseBox = () =>
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  const [posts, setPosts] = useState([]);
  const [sectionActive, setSectionActive] = useState("1");
  const [userComment, setUserComment] = useState({});
  const [selectedPost, setSelectedPost] = useState({});
  const [currentFilter, setcurrentFilter] = useState("NaN-aN-aN");
  const [lastFiter, setLastFiter] = useState({});

  const getProfile = () => {
    backendService.userProfile().then((data) => {
      let userProfile = {
        name: data.firstName + " " + data.middleName + " " + data.lastName,
        genderIdentityFlag: data.genderIdentityFlag,
        urlProfileImage: data.urlProfileImage,
      };
      setUserComment(userProfile);
    });
  };

  const getPosts = async () => {
    clearVariable();
    companyPostViewService
      .getByFilters(
        currentFilter == "NaN-aN-aN" ? undefined : currentFilter,
        postCategoryId
      )
      .then((result) => {
        if (result && result.length > 0) {
          let item = {};
          if (
            props.match.params.id !== undefined &&
            props.match.params.postId !== undefined
          ) {
            getPost(props.match.params.postId);
          } else {
            item = result[0];
            handleSelectPost(item);
          }
          setPosts(result);
        }
      });
  };

  const getPost = (id) => {
    companyPostViewService.getById(id).then((result) => {
      if (result.postType === 0) {
        setComments(result.totalComments);
        setSelectedPost(result);
        postLikes(result, 0);
      } else if (result.postType === 1) {
        result.iconPath = result.urlImage;
      }
    });
  };

  // useEffect(() => {
  //   if (props.match.params.id !== undefined && props.match.params.postId !== undefined) {
  //     let newParam = { reportId: props.match.params.reportId };
  //     setPostComment(newParam);
  //   } else {
  //     clearVariable();
  //     if (currentFilter !== "NaN-aN-aN" && lastFiter.filterMonthDate !== undefined) {
  //       selectedPost.companyPostId = null;
  //       if (postCategoryId === lastFiter.postType) getPosts();
  //     }
  //   }
  // }, [currentFilter, props]);

  useEffect(() => {
    getPost(props.match.params.postId);
    getProfile();
    // if (props.match.params.postId === undefined) getPosts();
  }, []);

  // useEffect(() => {
  //   if (lastFiter.filterMonthDate !== undefined && postCategoryId === lastFiter.postType) {
  //     getProfile();
  //     let datelastFilter = formatDate(new Date(lastFiter.filterMonthDate));
  //     if (currentFilter === datelastFilter) {
  //       getPosts();
  //     } else {
  //       getPostDateFilter(new Date(lastFiter.filterMonthDate));
  //     }
  //   }
  // }, [lastFiter]);

  const handleSelectPost = (item) => {
    if (!item.isRead) {
      companyPostViewService
        .InsertRead({ CompanyPostId: item.companyPostId })
        .then((result) => {
          if (result) {
            setSelectedPost(result);
            postLikes(result, 0);
            if (posts.length > 0) {
              let items = posts.map((m) => {
                if (m.companyPostId === item.companyPostId) m.isRead = true;
                return m;
              });
            }
          }
        });
    } else {
      getPost(item.companyPostId);
    }
  };

  const handleOnUnRead = () => {
    let id = sectionActive === "1" ? selectedPost.companyPostId : "";
    companyPostViewService
      .DeleteReadPost({ CompanyPostId: id })
      .then((result) => {
        if (result) {
          setSelectedPost(result);
          let items = posts.map((m) => {
            if (m.companyPostId === id) m.isRead = false;
            return m;
          });
          postLikes(result, 0);
          setPosts(items);
        }
      });
  };

  const clearVariable = () => {
    setSelectedPost({});
    setPosts([]);
  };

  const postLikes = (post, order) => {
    const totalPost = post.like ? post.totalLikes * 5 - 4 : post.totalLikes * 5;
    setPostTotalLikes(totalPost);
  };

  const handleOnLike = () => {
    const id = sectionActive === "1" ? selectedPost.companyPostId : "";
    companyPostViewService.InsertLike({ CompanyPostId: id, CompanyPostCategoryId: CompanyPostCategoryTypes.PRIDEWORLD }).then((result) => {
      if (result) setSelectedPost(result);
      postLikes(result, 1);
    });
  };

  const handleOnUnLike = () => {
    const id = selectedPost.companyPostId;
    companyPostViewService
      .DeleteLikePost({ CompanyPostId: id })
      .then((result) => {
        if (result) setSelectedPost(result);
        postLikes(result, 0);
      });
  };

  const getPostDateFilter = (filterDate) => {
    const dateFilter = formatDate(filterDate);
    setcurrentFilter(dateFilter);
  };

  const formatDate = (date) => {
    let datestring =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return datestring;
  };

  useEffect(() => {
    if (currentFilter !== undefined && lastFiter.filterMonthDate !== undefined)
      getPosts();
  }, [currentFilter]);

  useEffect(() => {
    getPostDateFilter(new Date(lastFiter.filterMonthDate));
  }, [lastFiter]);

  //const [dropdownOpen, setDropdownOpen] = useState(false);
  //const toggleDots = () => setDropdownOpen((prevState) => !prevState);

  const goToGallery = () => (window.location.href = `/blog-company/gallery`);

  return (
    <article className="dashboard-container-page">
      <h2 className="d-flex-start">
        <Link to="/blog-company" className="btn-back-post">
          <i className="icon-chevron-left"></i>
        </Link>
        {props.titleComponent}
      </h2>
      <div className={`wrapper_worldpride_post`}>
        <div className="content_worldpride_post">
          <div className="head_worldpride_post">
            <div className={'head_worldpride_post__thumbnail'}>
              <div className="post_title">
                <p>
                  {selectedPost.publishDate && (
                      <Moment locale="es" format=" DD [de] MMMM [de] YYYY">
                        {selectedPost.publishDate}
                      </Moment>
                  )}
                </p>
                <h2>{selectedPost.title}</h2>

                {selectedPost.totalViews && (
                    <p className="views_post">
                      <span>{selectedPost.totalViews * 5} vista(s)</span>
                    </p>
                )}
              </div>
              {selectedPost.urlImage && (
                  <div className="post_thumb">
                    <img src={selectedPost.urlImage} alt="" />
                  </div>
              )}
            </div>
            <CompanyPostAttachment
                CompanyPostId={selectedPost.companyPostId}
                IsDelete={false}
            />
          </div>
          <div className="info_worldpride_post">
            <div dangerouslySetInnerHTML={{ __html: selectedPost.text }} />
          </div>
          <div className="comments_worldpride_post">
            <div className="barra_likes_container">
              {selectedPost.like ? (
                <Button className="btn-like liked" onClick={handleOnUnLike}>
                  <i className="icon-heart-fill "></i> Me gusta {postTotalLikes}
                </Button>
              ) : (
                <Button className="btn-like " onClick={handleOnLike}>
                  <i className="icon-heart-fill "></i> Me gusta {postTotalLikes}
                </Button>
              )}
              {/* <div className="likes-total">
                <span className="span_like red">
                  <i className="icon-heart-fill "></i>
                </span>
              </div> */}
            </div>
            <CommentsBox
              isShow={showCommentBox}
              companyPostId={selectedPost.companyPostId}
              handleCommentBox={handleCommentBox}
              profileUser={userComment}
              updateCountComment={setComments}
              reportId={postComment?.reportId}
              bandera={commentBandera}
              setBandera={setCommentBandera}
              postType={postType}
            />
          </div>
        </div>
        <aside className="aside_gallery">
          <Link to={`/blog-company/gallery`} className="gallery_content_link">
            <div className="bg-gradient-pride"></div>
            <img src={bgGallery} alt="Galeria Mundo Pride" />
            <p>Mundo Pride</p>
            <h2 className="mb-4">Galería </h2>
            <Button onClick={goToGallery}>
              Ir a Galería
            </Button>
          </Link>
        </aside>
      </div>
      <FooterHome />
    </article>
  );
}






